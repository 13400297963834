<template>
    <div>
        <div class="ct">
            <ul class="flex ul1">
                <li :class="title == item.id ? 'active' : ''" v-for="(item, index) in $store.state.MediaNav"
                    :key="index" @click="TabShow(item.id)">
                    {{ item.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: '',
    created() {
        // 请求导航栏
        this.curlGet('/api/medium/init').then(res => {
            if (res.data.code) {
                for (const i in res.data.data) {
                    if (res.data.data[i].title == this.$props.title) {
                        this.$store.state.TypeItem = {
                            id: res.data.data[i].id,
                            title: res.data.data[i].title
                        }
                    }
                }

                this.$store.state.MediaNav = []
                this.$store.state.Media = []
                this.$store.state.Media = res.data.data
                for (const i in res.data.data) {
                    this.$store.state.MediaNav.push({
                        title: res.data.data[i].title,
                        id: res.data.data[i].id
                    })
                }
            }
            // console.log(this.$store.state.MediaNav);
        })
    },
    props: {
        title: String,
    },
    computed: {
        ...mapState(['Media', 'MediaNav', 'TypeItem'])
    },
    mounted() {

    },
    data() {
        return {
            Ctitle: '',
        }
    },
    methods: {
        TabShow(id) {
            this.$store.state.TypeItem = id
            console.log(this.$store.state.TypeItem, 'TypeItem');
            // console.log(this.$store.state.Media, 'Media');
            switch (this.$store.state.TypeItem) {
                case 1:
                    this.$router.push({
                        path: '/media/News',
                    })
                    break;
                case 2:
                    this.$router.push({
                        path: '/media/WeMedia'
                    })
                    break;
                case 3:
                    this.$router.push({
                        path: '/media/PaperMedia'
                    })
                    break;
                case 4:
                    this.$router.push({
                        path: '/media/OfficialAccounts'
                    })
                    break;
                case 5:
                    this.$router.push({
                        path: '/media/Microblog'
                    })
                    break;
                case 6:
                    this.$router.push({
                        path: '/media/RedBook'
                    })
                    break;
                case 7:
                    this.$router.push({
                        path: '/media/ShortVideo'
                    })
                    break;
                case 99:
                    this.$router.push({
                        path: '/media/SetMedia'
                    })
                    break;
                default:
                    break;
            }
        }
    },
}
</script>

<style lang=scss scoped>
.ul1 {
    padding: 0.3rem 0;
    border-bottom: 0.1rem solid #cec8c8;
    align-items: center;

    li {
        padding: 1rem 1rem;
        font-size: 1.2rem;
        transition: 0.2s all;
        border-radius: 0.5rem;
        cursor: pointer;

        &:hover {
            color: #168ad9;
        }
    }
}

/* // 被选中 */
.active {
    color: #fff !important;
    background: #168ad9;
    font-size: 1.22rem;

    &:hover {
        color: #fff !important;
    }
}
</style>